import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import InfluentialDevices from '@components/global/devices/InfluentialDevices'
import phonePlaceholder from '/content/assets/influential-20/portfolio/portfolio-phone-placeholder.png'
import * as cx from './Portfolio.module.scss'

const Portfolio = ({
  wrapperClassName,
  bg,
  heading,
  subheading,
  paragraph1,
  paragraph2,
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-7',
  paragraphClassName = 'subtitle-5',
  devices,
}) => {
  return (
    <section
      className={clsx(wrapperClassName, [cx.wrapper])}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className={cx.container}>
        <h3 className={headingClassName}>{heading}</h3>
        <p className={paragraphClassName}>{paragraph1}</p>
        <InfluentialDevices
          wrapperClassName={cx.devices}
          phonePlaceholder={phonePlaceholder}
          laptopSrc={devices?.laptopContent}
          tabletSrc={devices?.tabletContent}
          phoneSrc={devices?.phoneContent}
          laptopAlt={devices?.laptopAlt}
          tabletAlt={devices?.tabletAlt}
          phoneAlt={devices?.phoneAlt}
        />
        <span className={subheadingClassName}>{subheading}</span>
        <p className={paragraphClassName}>{paragraph2}</p>
      </div>
    </section>
  )
}

Portfolio.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  devices: PropTypes.object,
}

export default Portfolio
