import { useStaticQuery, graphql } from 'gatsby'

const useInfluential20Metadata = () => {
  const query = useStaticQuery(graphql`
    query influential20Metadata {
      bannerBG: file(
        relativePath: { eq: "influential-20/banner/banner-bg.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      bannerBGPhone: file(
        relativePath: { eq: "influential-20/banner/banner-bg-phone.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      bannerLaptopPlaceholder: file(
        relativePath: {
          eq: "influential-20/banner/banner-laptop-placeholder.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      bannerLaptop: file(
        relativePath: { eq: "influential-20/banner/banner-laptop.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      bannerTablet: file(
        relativePath: { eq: "influential-20/banner/banner-tablet.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      bannerPhone: file(
        relativePath: { eq: "influential-20/banner/banner-phone.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }

      websitelistAllure: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-allure.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistJosh: file(
        relativePath: { eq: "influential-20/websitelist2/websitelist-josh.png" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistJohn: file(
        relativePath: { eq: "influential-20/websitelist2/websitelist-john.png" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistJills: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-jills.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistLux: file(
        relativePath: { eq: "influential-20/websitelist2/websitelist-lux.png" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistEla: file(
        relativePath: { eq: "influential-20/websitelist2/websitelist-ela.png" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistKumara: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-kumara.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistTracy: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-tracy.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistNartey: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-nartey.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistSharona: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-sharona.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistAaron: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-aaron.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistPinkert: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-pinkert.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistTatiana: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-tatiana.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistCalifornia: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-california.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistPratt: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-pratt.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistQuiana: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-quiana.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistTiffany: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-tiffany.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistIvan: file(
        relativePath: { eq: "influential-20/websitelist2/websitelist-ivan.png" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistLouise: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-louise.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }
      websitelistPollena: file(
        relativePath: {
          eq: "influential-20/websitelist2/websitelist-pollena.png"
        }
      ) {
        id
        ...Influential20ImageOptimize
      }

      accentAllure: file(
        relativePath: { eq: "influential-20/accent/allure.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentJosh: file(relativePath: { eq: "influential-20/accent/josh.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      accentJohn: file(relativePath: { eq: "influential-20/accent/john.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      accentJills: file(
        relativePath: { eq: "influential-20/accent/jills.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentLux: file(relativePath: { eq: "influential-20/accent/lux.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      accentEla: file(relativePath: { eq: "influential-20/accent/ela.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      accentKumara: file(
        relativePath: { eq: "influential-20/accent/kumara.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentTracy: file(
        relativePath: { eq: "influential-20/accent/tracy.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentNartey: file(
        relativePath: { eq: "influential-20/accent/nartey.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentSharona: file(
        relativePath: { eq: "influential-20/accent/sharona.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentAaron: file(
        relativePath: { eq: "influential-20/accent/aaron.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentPinkert: file(
        relativePath: { eq: "influential-20/accent/pinkert.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentTatiana: file(
        relativePath: { eq: "influential-20/accent/tatiana.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentCalifornia: file(
        relativePath: { eq: "influential-20/accent/california.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentPratt: file(
        relativePath: { eq: "influential-20/accent/pratt.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentQuiana: file(
        relativePath: { eq: "influential-20/accent/quiana.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentTiffany: file(
        relativePath: { eq: "influential-20/accent/tiffany.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentIvan: file(relativePath: { eq: "influential-20/accent/ivan.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      accentLouise: file(
        relativePath: { eq: "influential-20/accent/louise.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      accentPollena: file(
        relativePath: { eq: "influential-20/accent/pollena.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }

      portfolioBG: file(
        relativePath: { eq: "influential-20/portfolio/portfolio-bg.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      portfolioPhone: file(
        relativePath: { eq: "influential-20/portfolio/portfolio-phone.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      portfolioTablet: file(
        relativePath: { eq: "influential-20/portfolio/portfolio-tablet.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }

      captivateLaptop: file(
        relativePath: { eq: "influential-20/others/captivate-laptop.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      captivateTablet: file(
        relativePath: { eq: "influential-20/others/captivate-tablet.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      homeBG: file(relativePath: { eq: "influential-20/others/home-bg.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      homeTablet: file(
        relativePath: { eq: "influential-20/others/home-tablet.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      propertyBG: file(
        relativePath: { eq: "influential-20/others/property-bg.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
      propertyLaptop: file(
        relativePath: { eq: "influential-20/others/property-laptop.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }

      formBG: file(relativePath: { eq: "influential-20/others/form-bg.jpg" }) {
        id
        ...Influential20ImageOptimize
      }
      formBGPhone: file(
        relativePath: { eq: "influential-20/others/form-bg-phone.jpg" }
      ) {
        id
        ...Influential20ImageOptimize
      }
    }

    fragment Influential20ImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)
  return query
}

export default useInfluential20Metadata
