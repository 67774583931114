import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import Banner from '@components/global/banner/Banner'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  WebsiteList,
  ImageSection,
  Portfolio,
} from '@components/pages/influential-20-websites'

import {
  pageData as page,
  bannerData,
  websiteListData,
  imageSectionData,
  portfolioData,
} from '@src/data/Influential20Websites'
import useInfluential20Metadata from '@hooks/influential-20-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './20InfluentialWebsites.module.scss'

const Influential20Websites = () => {
  const {
    bannerBG,
    bannerBGPhone,
    bannerLaptop,
    bannerTablet,
    bannerPhone,
    websitelistAllure,
    websitelistJosh,
    websitelistJohn,
    websitelistJills,
    websitelistLux,
    websitelistEla,
    websitelistKumara,
    websitelistTracy,
    websitelistNartey,
    websitelistSharona,
    websitelistAaron,
    websitelistPinkert,
    websitelistTatiana,
    websitelistCalifornia,
    websitelistPratt,
    websitelistQuiana,
    websitelistTiffany,
    websitelistIvan,
    websitelistLouise,
    websitelistPollena,
    accentAllure,
    accentJosh,
    accentJohn,
    accentJills,
    accentLux,
    accentEla,
    accentKumara,
    accentTracy,
    accentNartey,
    accentSharona,
    accentAaron,
    accentPinkert,
    accentTatiana,
    accentCalifornia,
    accentPratt,
    accentQuiana,
    accentTiffany,
    accentIvan,
    accentLouise,
    accentPollena,
    portfolioBG,
    portfolioPhone,
    portfolioTablet,
    captivateLaptop,
    captivateTablet,
    homeBG,
    homeTablet,
    propertyBG,
    propertyLaptop,
    formBG,
    formBGPhone,
  } = useInfluential20Metadata()
  const isPhone = useWindowSize().width <= 767
  const isBannerPhone = useWindowSize().width <= 414

  const websiteListImages = [
    { img: websitelistAllure, accent: accentAllure },
    { img: websitelistJosh, accent: accentJosh },
    { img: websitelistJohn, accent: accentJohn },
    { img: websitelistJills, accent: accentJills },
    { img: websitelistLux, accent: accentLux },
    { img: websitelistEla, accent: accentEla },
    { img: websitelistKumara, accent: accentKumara },
    { img: websitelistTracy, accent: accentTracy },
    { img: websitelistNartey, accent: accentNartey },
    { img: websitelistSharona, accent: accentSharona },
    { img: websitelistAaron, accent: accentAaron },
    { img: websitelistPinkert, accent: accentPinkert },
    { img: websitelistTatiana, accent: accentTatiana },
    { img: websitelistCalifornia, accent: accentCalifornia },
    { img: websitelistPratt, accent: accentPratt },
    { img: websitelistQuiana, accent: accentQuiana },
    { img: websitelistTiffany, accent: accentTiffany },
    { img: websitelistIvan, accent: accentIvan },
    { img: websitelistLouise, accent: accentLouise },
    { img: websitelistPollena, accent: accentPollena },
  ]

  const imageDevicesData = [
    {
      tabletContent: extractImage(homeTablet),
      tabletAlt: `${imageSectionData?.heading} tablet`,
    },
    {
      laptopContent: extractImage(captivateLaptop),
      laptopAlt: `${imageSectionData[1]?.heading} laptop`,
      tabletContent: extractImage(captivateTablet),
      tabletAlt: `${imageSectionData[1]?.heading} tablet`,
    },
    {
      laptopContent: extractImage(propertyLaptop),
      laptopAlt: `${imageSectionData[2]?.heading} laptop`,
      bg: extractImage(propertyBG),
    },
    {
      laptopContent: extractImage(bannerLaptop),
      laptopAlt: `${websiteListData.websiteList?.websiteItem[3]} laptop`,
      tabletContent: extractImage(portfolioTablet),
      tabletAlt: `${websiteListData.websiteList?.websiteItem[3]} tablet`,
      phoneContent: extractImage(portfolioPhone),
      phoneAlt: `${websiteListData.websiteList?.websiteItem[3]} phone`,
    },
  ]

  const isTablet = useMedia(media.tablet)

  return (
    <LayoutInnerPage
      wrapperClassName={cx.wrapper}
      buttonsHeight={page.buttonsHeight}
    >
      <Seo title={page.title} uri={page.uri} />
      <Banner
        wrapperClassName={cx.banner}
        layout={bannerData.layout.container}
        formLayout={bannerData.form.layout}
        bgImage={extractImage(isBannerPhone ? bannerBGPhone : bannerBG)}
        breadcrumbs={page.breadcrumbs}
        title={bannerData.heading.text}
        subtitle2={bannerData.subheading.text}
        titleClassName={
          isTablet
            ? bannerData.heading.tabletClassName
            : bannerData.heading.className
        }
        subtitle2ClassName={
          isTablet
            ? bannerData.subheading.tabletClassName
            : bannerData.subheading.className
        }
        formHeadingClassName={bannerData.form.subheadingClassName}
        buttonClassName={
          isTablet
            ? bannerData.form.buttonTabletClassName
            : bannerData.form.buttonClassName
        }
        devices={{
          layout: bannerData.layout.devices,
          phone: extractImage(bannerPhone),
          tablet: extractImage(bannerTablet),
          laptop: extractImage(bannerLaptop),
        }}
      />
      <WebsiteList
        wrapperClassName={cx.websiteList}
        heading1={websiteListData.heading.text1}
        heading2={websiteListData.heading.text2}
        subheading1={websiteListData.subheading.text1}
        subheading2={websiteListData.subheading.text2}
        buttonText={websiteListData.buttonText}
        items={websiteListData.websiteList.websiteItem}
        imgItems={websiteListImages}
      />
      <ImageSection
        tablet
        heading={imageSectionData[0].heading}
        paragraph={imageSectionData[0].paragraph}
        bg={extractImage(homeBG)}
        devices={imageDevicesData[0]}
      />
      <ImageSection
        laptopTablet
        wrapperClassName={cx.imageSection2}
        heading={imageSectionData[1].heading}
        paragraph={imageSectionData[1].paragraph}
        devices={imageDevicesData[1]}
      />
      <ImageSection
        laptop
        wrapperClassName={cx.imageSection3}
        heading={imageSectionData[2].heading}
        paragraph={imageSectionData[2].paragraph}
        bg={extractImage(propertyBG)}
        devices={imageDevicesData[2]}
      />
      <Portfolio
        bg={extractImage(portfolioBG)}
        heading={portfolioData.heading.text}
        subheading={portfolioData.subheading.text}
        paragraph1={portfolioData.paragraph.text1}
        paragraph2={portfolioData.paragraph.text2}
        devices={imageDevicesData[3]}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.modelOrBackground}
        background={getImage(isPhone ? formBGPhone : formBG)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Influential20Websites
