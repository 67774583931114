import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import useMedia, { media } from '@hooks/useMedia'
import { useIntersectOnMobile } from '@hooks/useIntersect'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import * as cx from './WebsiteList.module.scss'

const WebsiteItem = ({
  product,
  number,
  difference,
  features,
  works,
  buttonText,
  productImg,
  bgAccent,
  productClassName = 'heading-3',
  paragraphClassName = 'subtitle-5',
  paragraphTabletClassName = 'default-body',
  smallHeadingClassName = 'small-heading',
  ctaOnClick,
}) => {
  const [cardRef, isCardIntersecting, isMobile] = useIntersectOnMobile(
    {
      root: null,
      threshold: 0.7,
    },
    767
  )
  const isTablet = useMedia(media.tablet)
  const paragraphRenderedClass = isTablet
    ? paragraphTabletClassName
    : paragraphClassName

  return (
    <li className={cx.itemWrapper}>
      <div className={cx.item}>
        <div className={cx.content}>
          <div
            className={cx.bgAccent}
            style={{
              backgroundImage: `url(${bgAccent})`,
            }}
          />
          <div className={cx.innerContent}>
            <span className={cx.number}>{number}</span>
            <h4 className={productClassName}>{product}</h4>
            <span className={smallHeadingClassName}>
              Why it&apos;s different
            </span>
            <p className={paragraphRenderedClass}> {difference}</p>

            <span className={smallHeadingClassName}>
              Browse through this website to find
            </span>

            <ul className={cx.features}>
              {features?.map((text, key) => {
                return (
                  <li key={key} className={paragraphRenderedClass}>
                    <SvgCheckedCircle className={cx.checkSvg} />
                    <span>{text}</span>
                  </li>
                )
              })}
            </ul>

            <span className={smallHeadingClassName}>Why it works</span>
            <p className={paragraphRenderedClass}> {works}</p>
          </div>
        </div>
        <div
          ref={cardRef}
          className={clsx([cx.productImg], {
            [cx.cardIntersect]: isMobile && isCardIntersecting,
          })}
        >
          <div>
            <div
              className={clsx([cx.bgGradient], {
                'tempo-hidden': isMobile && !isCardIntersecting,
              })}
            />
            <ElementImage
              src={productImg}
              alt={product}
              width={1000}
              height={1006}
            />

            <div
              className={clsx([cx.bgAccentMobile], {
                'tempo-hidden': isMobile && !isCardIntersecting,
              })}
              style={{
                backgroundImage: `url(${bgAccent})`,
              }}
            />
          </div>
          <Button large text={buttonText} onClick={ctaOnClick} />
        </div>
      </div>
    </li>
  )
}

const WebsiteList = ({
  wrapperClassName,
  heading1,
  heading2,
  subheading1,
  subheading2,
  buttonText,
  headingClassName = 'heading-6',
  subheadingClassName = 'subtitle-5',
  subheadingTabletClassName = 'default-body',
  items: websiteItem,
  imgItems,
}) => {
  const isTablet = useMedia(media.tablet)
  const modalRef = useRef()

  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <ModalForm
        childFunc={modalRef}
        desktopTitle="Sign Up Today For A <br>Free Consultation"
        mobileTitle="Sign Up Today For A <br>Free Consultation"
        template="m1"
        type="b"
      >
        <FormSignUp
          submitLabel="Get Started Today"
          labelClassName="desktop-only"
          messageLabel="Questions or Comments"
        />
      </ModalForm>

      <h2 className={cx.header}>
        <span className={headingClassName}>{heading1}</span>
        <span className={headingClassName}>{heading2}</span>
        <div
          className={isTablet ? subheadingTabletClassName : subheadingClassName}
        >
          <span>{parse(subheading1)}</span>
          <span>{parse(subheading2)}</span>
        </div>
      </h2>
      <ol className={cx.list}>
        {websiteItem?.map((item, key) => {
          let number = key + 1
          number = number <= 9 ? `0${number}` : `${number}`
          return (
            <WebsiteItem
              key={key}
              number={number}
              product={item?.product}
              difference={item?.difference}
              features={item?.features}
              works={item?.works}
              buttonText={buttonText}
              productImg={extractImage(imgItems[key]?.img)}
              bgAccent={extractImage(imgItems[key]?.accent)}
              ctaOnClick={() => modalRef.current.openModal()}
            />
          )
        })}
      </ol>
    </section>
  )
}

WebsiteItem.propTypes = {
  wrapperClassName: PropTypes.string,
  product: PropTypes.string,
  number: PropTypes.string,
  difference: PropTypes.string,
  features: PropTypes.array,
  works: PropTypes.string,
  buttonText: PropTypes.string,
  bgAccent: PropTypes.string,
  productImg: PropTypes.string,
  productClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  paragraphTabletClassName: PropTypes.string,
  ctaOnClick: PropTypes.func,
}

WebsiteList.propTypes = {
  wrapperClassName: PropTypes.string,
  bgAccent: PropTypes.string,
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  subheading1: PropTypes.string,
  subheading2: PropTypes.string,
  buttonText: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  subheadingTabletClassName: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
}

export default WebsiteList
