import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import DeviceLaptopAlt from '@components/global/devices/LaptopAlt'
import DeviceTabletAlt from '@components/global/devices/TabletAlt'
import laptopPlaceholder from '/content/assets/influential-20/banner/banner-laptop-placeholder.png'
import tabletPlaceholder from '/content/assets/influential-20/banner/banner-tablet-placeholder.png'
import ElementImage from '@components/global/element/Image'
import * as cx from './ImageSection.module.scss'

const DeviceImg = ({
  wrapperClassName,
  devices,
  laptop,
  tablet,
  laptopTablet,
}) => {
  return (
    <div className={clsx(wrapperClassName, [cx.imageContainer])}>
      <canvas width={547} height={316} className={cx.placeholder}></canvas>

      {tablet && (
        <DeviceTabletAlt
          className={cx.tablet}
          imagePlaceholder={tabletPlaceholder}
          imageSrc={devices.tabletContent}
          imageFileAlt={devices.tabletAlt}
        />
      )}

      {laptop && (
        <DeviceLaptopAlt
          className={cx.laptop}
          imagePlaceholder={laptopPlaceholder}
          imageSrc={devices.laptopContent}
          imageFileAlt={devices.laptopAlt}
        />
      )}

      {laptopTablet && (
        <div className={cx.laptopTabletContainer}>
          <canvas width={547} height={316} className={cx.placeholder}></canvas>
          <DeviceTabletAlt
            className={cx.tablet}
            imagePlaceholder={tabletPlaceholder}
            imageSrc={devices.tabletContent}
            imageFileAlt={devices.tabletAlt}
          />
          <DeviceLaptopAlt
            className={cx.laptop}
            imagePlaceholder={laptopPlaceholder}
            imageSrc={devices.laptopContent}
            imageFileAlt={devices.laptopAlt}
          />
        </div>
      )}
    </div>
  )
}

const ImageSection = ({
  wrapperClassName,
  heading,
  paragraph,
  headingClassName = 'heading-5',
  paragraphClassName = 'subtitle-5',
  bg,
  devices,
  tablet,
  laptop,
  laptopTablet,
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.container}>
        <div className={cx.content}>
          <h3 className={headingClassName}>{heading}</h3>
          <p className={paragraphClassName}>{paragraph}</p>
        </div>

        {tablet && <DeviceImg tablet devices={devices} />}
        {laptopTablet && <DeviceImg laptopTablet devices={devices} />}
        {laptop && <DeviceImg laptop devices={devices} />}
      </div>

      {bg && (
        <div className={cx.bg}>
          <ElementImage src={bg} alt={`${heading} background`} />
        </div>
      )}
    </section>
  )
}

DeviceImg.propTypes = {
  wrapperClassName: PropTypes.string,
  deviceImg: PropTypes.object,
  laptop: PropTypes.bool,
  tablet: PropTypes.bool,
  laptopTablet: PropTypes.bool,
}

ImageSection.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  headingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
  bg: PropTypes.string,
  devices: PropTypes.object,
  tablet: PropTypes.bool,
  laptop: PropTypes.bool,
  laptopTablet: PropTypes.bool,
}

export default ImageSection
