// extracted by mini-css-extract-plugin
export var bgAccent = "WebsiteList-module--bg-accent--d0042";
export var bgAccentMobile = "WebsiteList-module--bg-accent-mobile--b932e";
export var bgGradient = "WebsiteList-module--bg-gradient--90459";
export var cardIntersect = "WebsiteList-module--card-intersect--6d617";
export var checkSvg = "WebsiteList-module--check-svg--e2f9c";
export var content = "WebsiteList-module--content--68ad2";
export var features = "WebsiteList-module--features--ce18c";
export var header = "WebsiteList-module--header--b1ccd";
export var innerContent = "WebsiteList-module--inner-content--5f753";
export var item = "WebsiteList-module--item--be94e";
export var itemWrapper = "WebsiteList-module--item-wrapper--e7f2c";
export var list = "WebsiteList-module--list--e1b7a";
export var number = "WebsiteList-module--number--10e10";
export var productImg = "WebsiteList-module--product-img--fa113";
export var wrapper = "WebsiteList-module--wrapper--a2db6";