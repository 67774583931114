export const pageData = {
  title: '20 Influential Real Estate Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: '20 Influential Real Estate Websites',
      url: '/20-influential-real-estate-websites/',
    },
  ],
  uri: '/20-influential-real-estate-websites/',
  buttonsHeight: { desktop: 1990, tablet: 1280, phone: 2880 },
  lcf: {
    version: 'LCF04',
    modelOrBackground: 'Britney',
    title: 'Free Consultation',
    subtitle: 'Get in touch with us for a',
    submitLabel: 'I want a stunning website!',
    leadName: 'Lead Contact Form',
    layout: 'B',
  },
}

export const bannerData = {
  layout: { container: 'product-gallery', devices: 'InfluentialDevices' },
  heading: {
    text: '20 Influential Real Estate Websites',
    className: 'heading-1',
    tabletClassName: 'heading-3',
  },
  subheading: {
    text: 'Most Popular Websites With Unique Design Features',
    className: 'subtitle-5',
    tabletClassName: 'default-body',
  },
  form: {
    titleClassName: 'subtitle-2',
    subheadingClassName: 'subtitle-6',
    buttonClassName: 'primary-button large',
    buttonTabletClassName: 'primary-button small',
  },
}

export const websiteListData = {
  heading: {
    text1:
      'There are 1 billion websites out there today. How does yours compare?',
    text2:
      'More to the point, how do you make your website the go-to source for buyers & sellers?',
    className: 'heading-6',
  },

  subheading: {
    text1: 'The key, of course, is to stand out – in<br/> a big, bold way. ',
    text2:
      ' Luckily, Agent Image<br/> is an expert at creating websites that wow visitors and win awards.<br/> Just take a look at these trendsetting<br/> sites we designed for these elite agents:',
    className: 'subtitle-5',
    tabletClassName: 'default-body',
  },
  buttonText: 'Get pricing & features',
  websiteList: {
    productClassName: 'heading-3',
    paragraphClassName: 'subtitle-5',
    paragraphTabletClassName: 'default-body',
    websiteItem: [
      {
        product: 'Allure Realty Homes',
        difference:
          'There’s nothing more alluring than elegance, and AllureRealtyHomes.com perfectly embodies this principle. The masterful use of dark tones and muted colors create a look that’s simultaneously evocative and exclusive.',
        features: [
          'Powerbroker Sharelle Rosado striking a power pose',
          'A gallery of high-end listings',
          'The entire Allure Realty Homes team, stars of the Netflix hit “Selling Tampa"',
          'A rundown of the team’s impressive sales record',
        ],
        works:
          'The website stays true to Sharelle’s vision of an all-female brokerage where each agent is empowered to succeed. The dark theme is tempered by touches of pink – the perfect interplay of power and femininity. ',
      },
      {
        product: 'Josh Flagg',
        difference:
          'JoshFlagg.com bucks the trend by offering a kind of “anteroom” before its actual homepage, creating a sense of intrigue and exclusivity. Hit “Click to Enter” and you’ll get access to some of the most elite listings in Los Angeles.',
        features: [
          'Two options for property search: by featured listing and by location',
          'Video section that features properties, interviews, and Josh’s vlog',
          'Clips from Million Dollar Listings Los Angeles where Josh stars',
          'A list of impressive clients – both local and international',
        ],
        works:
          'The website’s unconventional approach works because of the cache that comes with Josh Flagg’s name. Moreover, the intriguing design of the preview page — which features a dramatic bird’s eye view of the LA skyline — immediately draws the eyes.',
      },
      {
        product: 'John Kirkpatrick',
        difference:
          'John Kirkpatrick is a patron of San Francisco’s arts scene, and this love manifests itself in his site’s web design. Architectural lines cleanly divide sections of the home page, opening up to a warm welcome and the starting line to home searches.',
        features: [
          'Active and recently sold luxury listings',
          'A welcome note',
          'Client testimonials',
        ],
        works:
          'John Kirkpatrick’s real estate website immediately grabs the attention of prospective clients through a beautiful display of high-definition photography. This slideshow actually showcases the featured properties. Although rich in images, the site’s layout is classic and straightforward, with easy navigation and clean fonts.',
      },
      {
        product: 'Jills Zeder Group',
        difference:
          'Two things set apart The Jills Zeder Group: its access to the most prestigious listings and its world-class client servicing. And we made sure to highlight both in JillsZeder.com, the preeminent online resource for luxury real estate in Florida. Our work for this client garnered national attention, too, bagging the #3 Best Overall Website award at the 2021 RealTrends Website Rankings.',
        features: [
          'Magazine-like presentation of high-end listings',
          'User-friendly quick search feature',
          'Rundown of the team’s impressive sales record',
          'Handy features like neighborhood guides and interactive maps',
        ],
        works:
          'The strategic use of color allows the website to embody the breezy seaside living that awaits buyers in Florida. Our design was also built to showcase the stunning homes The Jills Zeder Group has access to.',
      },
      {
        product: 'The Lux Group',
        difference:
          'The site’s sleek, modern look combines seamlessly with a dynamic scrolling effect, which makes it a joy to browse through. The site’s seamless layout entices people to explore its various sections — and encourages them to take a closer look at the listings on offer.',
        features: [
          'A stylish Featured Communities section highlighted by some of LA’s most iconic landmarks and destinations',
          'Animated links to the site’s essential sections',
          'Featured Properties slideshow',
          'Blog article previews',
        ],
        works:
          'Everything in this site just gels together flawlessly, creating a natural flow enhanced by gorgeous images and attractive yet subtle animations that provide that much needed visual flair.',
      },
      {
        product: 'Ela Luxurious',
        difference:
          'This website marries a minimalist layout, eye-catching panoramic images, and an elegant blue-and-gold color scheme to encapsulate Florida’s luxurious oceanfront lifestyle. Its design has a sophisticated appeal without being intimidating, inviting visitors to browse leisurely as if they’re on the sands of the Sunshine State’s picturesque coastline.',
        features: [
          'Brief intro on Ela Luxurious',
          'Featured Luxurious Listings',
          'Featured New Developments section',
          'Links to Featured Neighborhoods',
        ],
        works:
          'The site does a superb job of engaging visitors using neatly arranged elements and a seamless flow, while successfully pulling off a sophisticated and modern look that feels very approachable.',
      },
      {
        product: 'Kumara Wilcoxon',
        difference:
          'Many mistake luxury for excess, but not Kumara Wilcoxon. This top agent understands that minimalism can be artful and impactful when done right — which is exactly the case for her website.',
        features: [
          'Spare yet sophisticated art design',
          'Photos that turn full-color when hovered over',
          'Short film introducing Kumara Wilcoxon',
          'Sales record totaling over $1 billion',
        ],
        works:
          'The website’s understated elegance clearly communicates Kumara’s refined tastes, giving clients assurance that she intimately understands what they need.',
      },
      {
        product: 'Tracy Tutor',
        difference:
          'Out with the old and in with the new with this Beverly Hills realtor! Tracy Tutor’s site embraces a truly modern look with clean geometric lines and elegant fonts. It exudes the natural confidence and strong presence this Million Dollar Listing top agent is known for.',
        features: [
          'A short intro to Tracy Tutor and links to her team’s bios',
          'Black-and-white featured listings that come to vivid life with mouse-over animation',
          'Client testimonials',
          'The latest on Tracy via her media and social sections',
        ],
        works:
          'The site’s creative and dynamic layout is balanced with a timeless black and white color scheme. It’s found the perfect harmony of classically chic qualities to create an interesting style.',
      },
      {
        product: 'Nartey | Wilner',
        difference:
          'At a glance, the Nartey Wilner Group tells visitors exactly what they’re about—the best luxury real estate professionals in Las Vegas for athletes and celebrities. The site combines the vibrancy of Sin City with the extravagance of its luxury properties.',
        features: [
          'A brief “Who We Are” statement',
          '“Sellebrity” section with testimonials from A-list clients',
          'Featured Properties in a great tile layout',
          'Links to their buying and selling services',
          'Featured Communities each complete with a succinct description of the area',
        ],
        works:
          'The design elements play well with the regal color scheme. The rich dark colors contrast with the gray and rust-colored tones, drawing attention to the site’s most important features.',
      },
      {
        product: 'Sharona Alperin',
        difference:
          'The site opens with the unmistakable beat of “My Sharona,” the biggest hit of the 1980s new wave group The Knack. It’s a fitting musical accompaniment; Sharona Alperin, after all, is not just the muse that inspired this power-pop classic, but she’s also one of the most successful real estate professionals in LA.',
        features: [
          'Real estate services',
          'Sharona’s professional credentials',
          'Testimonials',
          'Press stories',
          'A message facility she calls “Ask Sharona”',
        ],
        works:
          'It’s unusual enough to have music play throughout a real estate site, let alone one that’s iconic. But the real takeaway from this is that every website should find the hook that gets it noticed.',
      },
      {
        product: 'Aaron Kirman',
        difference:
          'As an agent with over $7 billion in career sales, Aaron Kirman is not afraid to showcase the prestigious estates he represents. Indeed, his ultra-deluxe listings are prominently featured on AaronKirman.com’s homepage – proof of the exclusive properties this renowned agent has access to. Meanwhile, the striking black-and-white aesthetic only elevates the site’s sense of prestige, as does the marble-like texture used in the background.',
        features: [
          'Portals for sellers, buyers, and home valuation',
          'Recent sales that showcase Aaron’s real estate prowess',
          'Estate Dynamics, Aaron’s AI-powered tech stack that makes selling a home a breeze',
          'Trailer for “Listing Impossible”, the hit TV show that features Aaron and his team',
        ],
        works:
          'The simple but stylish layout allows the properties to really shine, demonstrating Aaron’s impeccable taste and attention to detail.',
      },
      {
        product: 'Pinkert & Loratta',
        difference:
          "Unlike other real estate websites, this one lets the images shine with its clean layout and white background. The overall design is minimal, but it's a welcome invitation to Hawaii luxury properties nonetheless.",
        features: [
          'A beautiful slideshow of featured luxury properties',
          'A welcome note that is succinct yet warm',
          'Featured communities in the form of image links; hover and you get a brief intro about the neighborhood',
          'Client testimonials',
        ],
        works:
          'When it comes to Hawaii real estate, we’re used to a rainbow of colors. Pinkert & Loratta’s site sets itself apart with its minimalist tones. It focuses on showcasing the best in real estate offerings and user-friendliness.',
      },
      {
        product: 'Tatiana Derovanessian',
        difference:
          'The site’s cerulean-and-white color scheme brings to mind L.A.’s long stretch of beaches and its polished yet laidback feel. Important links – to the property search tool, the home valuation form, and the company’s about page – are right above the fold.',
        features: [
          'A brief intro detailing site owner Tatiana Derovanessian’s specializations',
          'The featured property listing of the week, including a video of the property',
          'Tatiana’s frequently updated Instagram feed',
        ],
        works:
          'Streamlined and elegant, it doesn’t bombard the visitor with too much information, allowing it to highlight the essentials.',
      },
      {
        product: 'California Life Properties',
        difference:
          'This website harnesses the power of video beautifully, taking visitors on a tour of California’s regions to showcase the diversity of lifestyles in the state. And since the video already does most of the talking, a theme of “less is more” permeates the rest of the website.',
        features: [
          'An interactive map featuring California Life Properties’ areas of specialization',
          'Buyer and seller resources in the form of circular image links',
          'A short but sweet welcome note',
          'Fan testimonies',
        ],
        works:
          'The embedded video that plays as soon as the site loads is the icing on the cake. It effectively sells the California lifestyle and beckons homebuyers and sellers to learn more about the real estate market. The interactive map is also a useful tool, helping visitors make sense of the areas geographically.',
      },
      {
        product: 'The Pratt Group',
        difference:
          'Brandi Pratt packages sunny Coachella Valley real estate in a sleek, black-themed website. The contrast is stark and deliberate. More importantly, it works. With the bold color scheme, The Pratt Group presents itself as confidently professional, while highlighting their featured luxurious desert homes through high-definition images that pop out on the viewer’s screen. The teal in the company logo also mirrors the cool blues of the clear sky and sparkling swimming pools in featured property photos.',
        features: [
          'An impressive showcase of top featured properties',
          'Quick links to Brandi and her team’s profiles',
          'Dedicated buttons for buyer- and seller-oriented services',
          'Direct links to the firm’s featured communities',
          'Prominent client testimonials and social media updates',
        ],
        works:
          'Southern California is well-known for its bright and sunny weather. By choosing a dark theme for its website, The Pratt Group deliberately cuts against the grain—a bold, strategic choice that ultimately makes its brand stand out more.',
      },
      {
        product: 'Quiana Watson',
        difference:
          'Browsing Quiana Watson’s website is like flipping through a high-end magazine. The crisp images, the scenes of domestic bliss, and the stylishly minimalist design reflect her reputation as one of Metro Atlanta’s leading luxury realtors.',
        features: [
          'Dedicated portals for buyers and sellers',
          'Useful tools like home valuation',
          'Interactive map with active listings',
          'Informative blog section',
        ],
        works:
          'The interplay between the dark color scheme and the vibrant photography create a look that’s undeniably refined but also enticing.',
      },
      {
        product: 'Team Tiffany Rochelle',
        difference:
          "Youthful and contemporary are two words that would aptly describe Team Tiffany Rochelle’s site. The stylish layout, akin to that of a fashion magazine’s, provide ample visual interest. The effective use of space and black-and-white color scheme result in a chic site that's perfect for a California specialist.",
        features: [
          'Quick property search with an advanced search option',
          'A welcome note with links to seller and buyer resources',
          'Featured listings with big, high-definition images',
          'Image links of featured communities',
        ],
        works:
          'Stylish though it may be, the design flair also has optimum user experience in mind. Everything is within reach, all text easy to read, and with enough decorate to sustain visitor interest.',
      },
      {
        product: 'Ivan Estrada',
        difference:
          'Ivan Estrada’s website boasts a contemporary design that’s characterized by geometric shapes, clean lines, and modern fonts. The parallax effect and animations make the browsing experience more engaging, while also serving as a nod to the young and dynamic agent’s service.',
        features: [
          'Video community tour of L.A.’s most iconic neighborhoods',
          'Ivan’s video series called “Real Estate Minutes”',
          'Dedicated section for press mentions',
          'Blog filled with up-to-date industry insights',
        ],
        works:
          'Every element of this site works together to depict Ivan as the energetic and passionate agent that he is.',
      },
      {
        product: 'Louise Phillips Forbes',
        difference:
          'Louise Phillips Forbes may be known for selling multi-million dollar properties, but her goal has always been to help families find the perfect home. For this reason, her website lies at the intersection between ritzy and cozy.',
        features: [
          'Aspirational introductory video',
          'Gallery of impressive listings',
          'Informative market reports',
          'Portals to past and current real estate developments',
        ],
        works:
          'The website truly embodies Louise, who effortlessly juggles being an in-demand realtor with being a devoted spouse and mom. This is reflected by the graceful use of both gray and pink tones on the site.',
      },
      {
        product: 'Pollena Forsman',
        difference:
          'This website perfectly captures the prestige, exclusivity, and leisurely lifestyle of Westchester County, one of the hottest property markets in New York State.',
        features: [
          'Banner video that showcases Westchester County',
          'Section on Pollena’s sales record',
          'Glowing client reviews',
          'Press coverage',
        ],
        works:
          'The website’s simple layout and neutral tones not only capture the locale’s stress-free vibe, but also allow the visual elements to truly stand out.',
      },
    ],
  },
}

export const imageSectionData = [
  {
    heading: 'Your Homepage Is Your First Selling Point',
    paragraph:
      'As a real estate professional, you want the first page of your website to showcase your strengths and the type of company that you’re representing. With a polished and professional real estate website theme, homebuyers will feel that they are purchasing property from an established and trustworthy company. Aside from having great design, your website should also be user-friendly and easy to navigate so your web visitors can intuitively find their way around your site content.',
  },
  {
    heading: 'Captivate Your Visitors With Beautiful Images',
    paragraph:
      'Homebuyers want to see high-quality and detailed photos of homes,  and what better way to showcase a featured property than on the homepage and property pages of your agent website? A gallery slider or a separate page for detailed photos should accompany each property listing. For special or featured listings, you can also include links to professionally-made property tour videos.',
  },
  {
    heading: 'Property Or MLS Search Feature',
    paragraph:
      'Your real estate website should have a property search feature where homebuyers can narrow down their searches by location, property type, price range, features, zip code, etc. Make sure that your IDX website offers different ways of searching such as: map search, basic search, advanced search,  and address/listing ID search.',
  },
]

export const portfolioData = {
  heading: {
    text: 'Let’s Make Your Website Stand Out',
    className: 'heading-5',
  },
  subheading: {
    text: 'THERE’S A PERFECT WEBSITE DESIGN PACKAGE FOR YOU!',
    className: 'subtitle-7',
  },

  paragraph: {
    text1:
      'With 20+ years of design expertise, Agent Image can give you a real estate website that as trendsetting as the one’s above.',
    text2:
      'We’ve got flexible packages that will suit your needs, whether you’re a new or established agent. Just chat with our Web Marketing Strategists to find out which one is right for you.',
    className: 'subtitle-5',
  },
}
